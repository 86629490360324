import { Request } from '@/plugins/helper/fetch';
const req = new Request('tomorder');
/*export async function doImport({tracking, D, R, C, KL, Shipping}){
	const resp = await req.put('import',{tracking, D, R, C, KL, Shipping});
	return await resp.json()
}*/
export async function getPDF(Tracking, Customer){
	const resp = await req.get('label?Tracking='+Tracking+'&Customer='+Customer);
	return await resp.blob();
}
export async function doImport({Order, Shipping, Tracking}){
	const resp = await req.put('import',{Order, Shipping, Tracking});
	return await resp.json()
}
export async function doSearch(tracking){
	const resp = await req.post('search',{tracking});
	return await resp.json()
}
export async function doList(query){
	const resp = await req.get('list?'+query);
	return await resp.json();
}
export async function doListMark(){
	const resp = await req.get('list_mark');
	return await resp.json();
}
export async function doShipments(){
	const resp = await req.get('shipments');
	return await resp.json();
}
export async function doShipmentPackage(id){
	const resp = await req.get('shipment/'+id);
	return await resp.json();
}
export async function doImportShipment(id,request){
	const resp = await req.put('import-shipment/'+id, request);
	return await resp.json();
}
export async function doCreateList(request){
	const resp = await req.put('create', request);
	return await resp.json();
}
export async function doUpdateList(id, list){
	const resp = await req.patch('update/'+id, {items:list});
	return await resp.json();
}
export default {
	doImport,
	doList,
	doListMark,
	doCreateList,
	doUpdateList,
	doImportShipment,
}