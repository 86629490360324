<template>
   <nav>
       <v-app-bar  color="#0f2e8c" dark app >
           <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
           <v-toolbar-title class="text-uppercase ">
               <span>TOMEXPRESS PORTAL</span>
           </v-toolbar-title>
           <v-spacer></v-spacer>
           <template v-if="isAuthorized">
							 <v-btn
								 class="mx-2"
								 color="blue"
								 dark
								 to="/wait-list-partner"
								 v-if="loginInfo._perm === 'admin'"
							 >
								 Hàng chờ Tracking
							 </v-btn>
							 <v-btn
								 class="mx-2"
								 color="blue"
								 dark
								 to="/import-partner"
								 v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'warehouse' || loginInfo._perm === 'manager'"
							 >
								 Nhập - Xuất Kho
							 </v-btn>
               <v-menu
                v-model="notificationMenu"
                :close-on-content-click="false"
                :nudge-width="250"
                offset-y
               >
                    <template v-slot:activator="{ on, attrs }" >
                        <!-- <notification-bell
                            :size="25"
                            :count="notificationsCount"
                            :ding="true"
                            :upperLimit=50
                            counterLocation="upperRight"
                            counterStyle="roundRectangle"
                            counterBackgroundColor="#FF0000"
                            counterTextColor="#FFFFFF"
                            iconColor="orange"
                            :animated="true"
                            :prefixPlus="true"
                            v-bind="attrs"
                            v-on="on"
                        /> -->
                        
                            <v-btn
                                color="orange"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                text
                            >
                            <v-badge overlap color="red" :content=notificationsCount v-if=notificationsCount>
                                <v-icon dark>
                                    mdi-bell
                                </v-icon>
                            </v-badge>
                            <v-icon dark v-else>
                                mdi-bell
                            </v-icon>
                            </v-btn>
                    </template>
                    <v-card>
                        <v-list>
                        <template  v-for="(data) in notifications">
                            <v-list-item :key=data.message v-if=data.show>
                                <v-list-item-content>
                                    <v-list-item-title><v-btn text color="green" small @click="LoadPage(data)">{{data.message}}</v-btn></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        </v-list>
                    </v-card>
               </v-menu>
                <v-btn text @click="onLogout">
                    <span>Thoát</span>
                    <v-icon right>exit_to_app</v-icon>
                </v-btn>
           </template>
       </v-app-bar>
      <v-navigation-drawer v-model="drawer" dark app color="#0f2e8c" v-if="isAuthorized" expand-on-hover>
          <v-layout column align-center>
               <v-flex class="mt-5">
                    <a href="/">
                      <img src="/maybay.png" alt="" height="50">
                    </a>
               </v-flex>
          </v-layout>
          <v-list>
              <v-list-item
                  v-for="link in links"  :key="link.text" router :to="link.route" active-class="border"
                  v-if="loginInfo._perm !== 'ctv'"
              >
                  <v-list-item-action>
                     <v-icon >{{link.icon}}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content >
                      <v-list-item-title >{{link.text}}</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item router to="/staff" active-class="border" v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || loginInfo._perm === 'sale'">
                  <v-list-item-action>
                     <v-icon >mdi-account-supervisor-circle</v-icon>
                  </v-list-item-action>
                  <v-list-item-content >
                      <v-list-item-title>Nhân sự</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
               <v-list-item router to="/news" active-class="border" v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'">
                  <v-list-item-action>
                     <v-icon >mdi-newspaper-variant</v-icon>
                  </v-list-item-action>
                  <v-list-item-content >
                      <v-list-item-title>Tin tức</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item router to="/system-notifications" active-class="border" v-if="(loginInfo._perm === 'admin')">
                <v-list-item-action>
                  <v-icon >mdi-message</v-icon>
                </v-list-item-action>
                <v-list-item-content >
                  <v-list-item-title>Thông báo App</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item router to="/customer" active-class="border" v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'sale' || loginInfo._perm === 'manager'">
                  <v-list-item-action>
                     <v-icon >mdi-account-supervisor-circle</v-icon>
                  </v-list-item-action>
                  <v-list-item-content >
                      <v-list-item-title>Khách hàng</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
            <v-list-item router to="/potential-customer" active-class="border" v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'sale' || loginInfo._perm === 'manager'">
              <v-list-item-action>
                <v-icon >mdi-account-supervisor-circle</v-icon>
              </v-list-item-action>
              <v-list-item-content >
                <v-list-item-title>KH Tiềm năng</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
							<v-list-group
								prepend-icon="mdi-handshake"
								no-action
								active-class="border"
								v-if="isAllowedPartner !== false"
							>
								<template v-slot:activator>
									<v-list-item>
										<v-list-item-content>
											<v-list-item-title>TOJAPAN</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</template>
								<v-list-item
									to="/partner/orders"
									router
									exact
									v-if="isAllowedPartner['Partner'] === true"
								>
									<v-list-item-content>
										<v-list-item-title>
											Đơn hàng
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
                <v-list-item
                    to="/partner/pending-orders"
                    router
                    exact
                    v-if="isAllowedPartner['Partner'] === true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Đơn hàng chờ ({{loginInfo['pending_order']}})
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
								<v-list-item
									to="/partner/auctions"
									router
									exact
									v-if="isAllowedPartner['Auction'] === true"
								>
									<v-list-item-content>
										<v-list-item-title>
											Đấu giá
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									to="/partner/accounts"
									router
									exact
									v-if="isAllowedPartner['Auction'] === true && this.loginInfo._perm !== 'accounting'"
								>
									<v-list-item-content>
										<v-list-item-title>
											Tài khoản Yahoo
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									to="/tags"
									router
									exact
									v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
								>
									<v-list-item-content>
										<v-list-item-title>
											Thẻ
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
                <v-list-item
                    to="/category/chung"
                    router
                    exact
                    v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Danh mục chung
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
								<v-list-item
									to="/category/yahoo_auction"
									router
									exact
									v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
								>
									<v-list-item-content>
										<v-list-item-title>
											Danh mục Y! Auctions
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									to="/category/mercari"
									router
									exact
									v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
								>
									<v-list-item-content>
										<v-list-item-title>
											Danh mục Mercari
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									to="/category/rakuten"
									router
									exact
									v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
								>
									<v-list-item-content>
										<v-list-item-title>
											Danh mục Rakuten
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
                <v-list-item
                    to="/category/amazon_japan"
                    router
                    exact
                    v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Danh mục Amazon Japan
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                    to="/category/yahoo_shopping"
                    router
                    exact
                    v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager'"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Danh mục Y! Shopping
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
							</v-list-group>
            <v-list-group
                prepend-icon="mdi-package-variant"
                no-action
                active-class="border"
                v-if="loginInfo._perm !== 'warehouse' && loginInfo._perm !== 'checker'"
            >
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>KHO HÀNG</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list-item router to="/kho-hang/nganh-hang" active-class="border">
                <v-list-item-content >
                  <v-list-item-title>Ngành hàng</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item router to="/kho-hang/kho/ton-kho" active-class="border">
                <v-list-item-content >
                  <v-list-item-title>Kho hàng</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item router to="/kho-hang/kho/don-hang" active-class="border">
                <v-list-item-content >
                  <v-list-item-title>Đơn hàng</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                  router to="/kho-hang/hoa-don"
                  active-class="border"
                  v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || loginInfo._perm === 'sale' || loginInfo._perm === 'ctv'"
              >
                <v-list-item-content >
                  <v-list-item-title>Hóa đơn</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
<!--						<v-list-item router to="/tom-order" active-class="border" v-if="(loginInfo._perm === 'admin' || loginInfo._perm === 'sale' || loginInfo._perm === 'manager') && loginInfo.Limited === false">
							<v-list-item-action>
								<v-icon >mdi-package-variant</v-icon>
							</v-list-item-action>
							<v-list-item-content >
								<v-list-item-title>Tom Order</v-list-item-title>
							</v-list-item-content>
						</v-list-item>-->
						<v-list-item router to="/thu-vien" active-class="border" v-if="loginInfo._perm === 'admin'">
							<v-list-item-action>
								<v-icon >mdi-image</v-icon>
							</v-list-item-action>
							<v-list-item-content >
								<v-list-item-title>Thư viện Banner</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
            <v-list-item router to="/search-logs" active-class="border" v-if="loginInfo._perm === 'admin'">
              <v-list-item-action>
                <v-icon >mdi-cloud-search</v-icon>
              </v-list-item-action>
              <v-list-item-content >
                <v-list-item-title>Search Logs</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
      </v-navigation-drawer>
   </nav>
</template>
<script>
import {Request} from '@/plugins/helper/fetch.js';
import { doImport } from '@/plugins/query/tomorder.js';
import { Status } from '@/plugins/helper/dstatus'
import {isPermissions} from '@/plugins/helper/validations.js';
// import NotificationBell from 'vue-notification-bell'
import Loading from '@/components/base/Loading.vue';
import {mapGetters, mapActions } from 'vuex'
export default {
		components:{
			Loading,
		},
		data() {
			return {
				notice: new Status(this.$swal),
				loadingTracking: false,
				importTracking: null,
				menuImportTO: false,
				notificationMenu: false,
				notificationsCount: 0,
				notifications: [],
				drawer: true,
				links: [
					{icon: 'mdi mdi-package', text: 'Shipment', route: '/shipments'},
					{icon: 'mdi mdi-account', text: 'Cá nhân', route: '/my'},
				],
				req: new Request('abcdef'),
				loop: null,
				tomorder:{},
			}
		},
    computed: {
        ...mapGetters(['authStatus','isAuthenticated','loginInfo']),
        isAuthorized(){
            if(this.authStatus ==="success" || this.isAuthenticated){
                return true;
            }
            return false;
        },
				isAllowedPartner(){
					if(this.loginInfo._perm === 'admin' || this.loginInfo._perm === 'manager' || this.loginInfo._perm === 'accounting'){
						return {
							Partner: true,
							Auction: true,
						};
					}
					if(this.loginInfo._perm === 'warehouse'){
						return {
							Partner: true,
							Auction: false,
						};
					}
					if(this.loginInfo._perm === 'sale') {
						if(this.loginInfo.Limited === false){
							return this.loginInfo.Rules;
						}
					}
					return false;
				},
    },
    methods:{
        ...mapActions(['LoginInfor']),
			isPermissions,
        LoadPage(data){
            this.$router.push(data.link).catch(()=>{});
        },
        onLogout() {
            this.$store.dispatch('AUTH_LOGOUT');
        },
        GetInfor(){
            if(this.authStatus === "success" || this.isAuthorized){
                this.req.getInfo().then(async resp=>{
									if(resp.status === 403){
										this.$store.dispatch('AUTH_LOGOUT');
									}else {
										const json = await resp.json()
										if (json._perm) {
											this.$store.dispatch('LoginInfor', json);
										}
									}
                }).catch(e=>{
									console.error('Fetch Error: ',e);
								})
            }
        }
    },
    beforeDestroy(){
      clearInterval(this.loop)
    },
    mounted(){
      this.loop = setInterval(this.GetInfor,10000)
      this.GetInfor()
    }
   
}
</script>
<style scoped>
.border {
  border-left: 4px solid #0093f5;
}
</style>
