import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/shipments',
    name: 'shipments',
    component: () => import('../views/Shipments.vue'),
  },
  {
    path: '/tags',
    name: 'tags',
    component: () => import('../views/Tags.vue'),
  },
  {
    path: '/system-notifications',
    name: 'notifications',
    component: () => import('../views/Notification.vue'),
  },
  {
    path: '/category/:id',
    name: 'category',
    component: () => import('../views/Category.vue'),
  },
  {
    path: '/shipment/:shipmentID',
    name: 'shipment',
    component: () => import('../views/Shipment.vue'),
  },
  {
    path: '/report/:shipmentID',
    name: 'report',
    component: () => import('../views/Report.vue'),
  },
  {
    path: '/Sale/report/:shipmentID',
    name: 'SaleReport',
    component: () => import('../views/ReportSale.vue'),
  },
  {
    path: '/staff',
    name: 'staff',
    component: () => import('../views/Staff.vue'),
  },
  {
    path: '/potential-customer',
    name: 'potential-customer',
    component: () => import('../views/KHTN.vue'),
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import('../views/Customer.vue'),
  },
  {
    path: '/tracking',
    name: 'tracking',
    component: () => import('../views/Tracking.vue'),
  },
  {
    path: '/my',
    name: 'my-account',
    component: () => import('../views/Canhan.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/News.vue'),
  },
  {
    path: '/dang-nhap',
    name: 'dang-nhap',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/tom-order',
    name: 'tom-order',
    component: () => import('../views/TomOrder.vue'),
  },
  {
    path: '/partner/orders',
    name: 'partner-orders',
    component: () => import('../views/Partner.vue'),
  },
  {
    path: '/partner/pending-orders',
    name: 'partner-pending-orders',
    component: () => import('../views/Partner-PendingOrders.vue'),
  },
  {
    path: '/partner/accounts',
    name: 'partner-yahoo-account',
    component: () => import('../views/YahooAccount.vue'),
  },
  {
    path: '/partner/auctions',
    name: 'partner-auctions',
    component: () => import('../views/YahooAuctions.vue'),
  },
  {
    path: '/import-partner',
    name: 'import-partner',
    component: () => import('../views/ImportPartner.vue'),
  },
  {
    path: '/wait-list-partner',
    name: 'wait-list-partner',
    component: () => import('../views/PTrack.vue'),
  },
  {
    path: '/thu-vien',
    name: 'thu-vien',
    component: () => import('../views/Library.vue'),
  },
  {
    path: '/kho-hang/nganh-hang',
    name: 'nganh-hang',
    component: () => import('../views/Kho/Nganh-Hang.vue'),
  },
  {
    path: '/kho-hang/kho/:Mode',
    name: 'kho-hang',
    component: () => import('../views/Kho/Kho-Hang.vue'),
  },
  {
    path: '/kho-hang/kho/:Mode/:NH',
    name: 'kho-hang-nh',
    component: () => import('../views/Kho/Kho-Hang.vue'),
  },
  {
    path: '/kho-hang/hoa-don',
    name: 'hoa-don',
    component: () => import('../views/Kho/Kho-Hoa-Don.vue'),
  },
  {
    path: '/search-logs',
    name: 'search-logs',
    component: () => import('../views/SearchLogs.vue'),
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
 router.beforeEach((to, from, next) => {
  const publicPages = ['/dang-nhap'];
  const authRequired = !publicPages.includes(to.path);
  const LoginPage = publicPages.includes(to.path);
  const loggedIn = store.getters.isAuthenticated;

  if (authRequired  && !loggedIn) {
    return next('/dang-nhap');
  }
  if(LoginPage && loggedIn){
    return next('/');
  }
  next();
}
);
export default router
