<template>
  <v-overlay
    absolute
    :value="status"
  >
    <v-progress-circular
      indeterminate
      color="primary"
      v-if="status"
    />
  </v-overlay>
</template>

<script>
export default {
  props: ["status"],

}
</script>

<style>

</style>