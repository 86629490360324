import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment-timezone'
import DateTimePicker from 'aasaam-vuetify-datetime/src/components/DateTimePicker';
import RangeDateTimePicker from 'aasaam-vuetify-datetime/src/components/RangeDateTimePicker';
import JsonExcel from "vue-json-excel";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/reset.css';
import mdiVue from 'mdi-vue/v2'
import * as mdijs from '@mdi/js'
import store from './store'
import { API } from '@/plugins/helper/api.js'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'  //Globally import VTextField
import VueHtml2Canvas from 'vue-html2canvas';
import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
	specs: [
		'fullscreen=no',
		'titlebar=no',
		'scrollbars=no'
	],
	styles: [
		'https://quanly.tomexpress.jp/assets/print_format.css'
		//'http://localhost/print.css'
	]
}
Vue.use(VueHtml2Canvas);
Vue.use(VueHtmlToPaper, options);
Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, { 
	locale: 'vi-VN',
	decimalLength: 0,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
   valueAsInteger: true,
  allowNegative: false
})
Vue.use(VueAxios, axios);
Vue.use(mdiVue, {
  icons: mdijs
});
Vue.use(VueSweetalert2);
Vue.component('RangeDateTimePicker', RangeDateTimePicker);
Vue.component('DateTimePicker', DateTimePicker);
Vue.component("downloadExcel", JsonExcel);
Vue.config.productionTip = false
Vue.prototype.$API_URL = API;
Vue.prototype.$Version = "v1.230604";
Vue.prototype.$Request = Vue.axios.create({
  baseURL: API,
  timeout: 60000,
  headers: {'Authorization': store.getters.authToken}
});
Vue.prototype.$Request.interceptors.response.use(undefined, err => {
  const error = err.response;
  if (error.status === 401 || error.status === 403){
    localStorage.removeItem('te-token');
  }
});
Vue.prototype.moment = moment;
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
