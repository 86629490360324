import { API } from './api.js'
import axios from 'axios'
export class Request {
  constructor (app) {
    this.Token = localStorage.getItem('te-token')
    this.Result = undefined
    this.app = `${API}/${app}`
    this.header = {
      method: 'GET',
      headers: {
        'Authorization': this.Token,
        'Content-Type': 'application/json'
      },
    },
    this.axios = axios.create({
        timeout: 60000,
        headers: { 
          'Authorization': this.Token,
          // 'Content-Type': 'multipart/form-data'
        }
    });
  }
  getInfo() {
    return fetch(API+'/informations',this.header);
  }
  fetch(id='') {
    return fetch(this.app+(id ? '/'+id:''),this.header);
  }
  byID(id='') {
    return fetch(this.app+'/byID/'+id,this.header);
  }
  Gop(id='',data) {
    const header = {
      ...this.header,
      method: 'POST', 
      body: JSON.stringify(data)
    }
    return fetch(this.app+'/gop/'+id,header);
  }
  UploadItem(id,data,onUploadProgress,Custom='upload') {
    let uploadData = new FormData();
    const file = data.file
    uploadData.append("file", file);
    delete data.file
    uploadData.append("submitData", JSON.stringify(data));
    // uploadData.append("countryCode", data.countryCode); 
    // uploadData.append("description", data.description); 
    // uploadData.append("enDescription", data.enDescription); 
    // uploadData.append("jaName", data.jaName); 
    // uploadData.append("name", data.name); 
    // uploadData.append("price", data.price); 
    // uploadData.append("unit", data.unit); 
    // uploadData.append("viDescription", data.viDescription); 
    // uploadData.append("viName", data.viName); 
    return this.axios.post(this.app+`/${Custom}/`+id,uploadData,onUploadProgress);
  }
  Upload(id,file,onUploadProgress,Custom='upload') {
    let uploadData = new FormData();
    uploadData.append("file", file);  
    return this.axios.post(this.app+`/${Custom}/`+id,uploadData,onUploadProgress);
  }
  UploadFile(uri,file) {
    let uploadData = new FormData();
    if(Array.isArray(file)){
      file.forEach(f=>uploadData.append("file", f));
    }else {
      uploadData.append("file", file);
    }
    return this.axios.post(this.app+uri,uploadData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  UploadData(uri, data){
    return this.axios.put(this.app+`/${uri}`,data);
  }
  ThuVien(file,onUploadProgress) {
    let uploadData = new FormData();
    uploadData.append("file", file);  
    return this.axios.post(this.app+`/thu-vien`,uploadData,onUploadProgress);
  }
  Go(id='') {
    const header = {
      ...this.header,
      method: 'DELETE', 
    }
    return fetch(this.app+'/gop/'+id,header);
  }
  search(id='',data) {
    const header = {
      ...this.header,
      method: 'POST', 
      body: JSON.stringify(data)
    }
    return fetch(this.app+'/'+id,header);
  }
  create(data) {
    const header = {
      ...this.header,
      method: 'PUT', 
      body: JSON.stringify(data)
    }
    return fetch(this.app+'/create',header)
  }
  update(id,data) {
    const header = {
      ...this.header,
      method: 'PATCH', 
      body: JSON.stringify(data)
    }
    return fetch(this.app+'/update/'+id,header)
  }
  lock(data) {
    const header = {
      ...this.header,
      method: 'PATCH',
      body: JSON.stringify(data)
    }
    return fetch(this.app+'/lock',header)
  }
  unlock(data) {
    const header = {
      ...this.header,
      method: 'PATCH',
      body: JSON.stringify(data)
    }
    return fetch(this.app+'/unlock',header)
  }
  delete(id) {
    const header = {
      ...this.header,
      method: 'DELETE', 
    }
    return fetch(this.app+'/delete/'+id,header)
  }
  note(id,data){
    const header = {
      ...this.header,
      method: 'PATCH', 
      body: JSON.stringify(data)
    }
    return fetch(this.app+'/note/'+id,header)
  }
  importTracking(id,data){
    const header = {
      ...this.header,
      method: 'POST', 
      body: JSON.stringify(data)
    }
    return fetch(this.app+'/import/'+id,header)
  }
  patch(uri,data){
    const header = {
      ...this.header,
      method: 'PATCH',
      body: JSON.stringify(data)
    }
    return fetch(this.app+'/'+uri,header)
  }
  put(uri,data, upload=false){
    const headers = !upload ? this.header : {
      headers:{
        ...this.header.headers,
        'Content-Type': 'multipart/form-data',
      }
    }
    const header = {
      ...headers,
      method: 'PUT',
      body: upload=== false ? JSON.stringify(data): data,
    }
    return fetch(this.app+'/'+uri,header)
  }
  get(uri){
    const header = {
      ...this.header,
      method: 'GET'
    }
    return fetch(this.app+'/'+uri,header)
  }
  remove(uri){
      const header = {
        ...this.header,
        method: 'DELETE',
      }
      return fetch(this.app+uri,header)
  }
  put_data(uri,data){
    const header = {
      ...this.header,
      method: 'PUT',
      body: JSON.stringify(data)
    }
    return fetch(this.app+'/'+uri,header)
  }
  post(uri,data){
    const header = {
      ...this.header,
      method: 'POST',
      body: JSON.stringify(data)
    }
    return fetch(this.app+'/'+uri,header)
  }
}