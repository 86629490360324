export class Status {
  constructor (app) {
    this.app = app
    this.cfg = {
      position: 'center',
      icon: 'error',
      showConfirmButton: false,
      timer: 1000
    }
  }
  success(title){
    const option = {
      ...this.cfg,
      title:title,
      icon: 'success'
    }
    return this.app.fire(option)
  }
  error(title){
    const option = {
      ...this.cfg,
      title:title,
    }
    return this.app.fire(option)
  }
  confirm(title,message = undefined){
    return this.app.fire({
      title: title,
      text:message,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Có',
      denyButtonText: `Không`,
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      } else if (result.isDenied) {
        return false;
      }
    })
  }
}