import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT
  } from "@/store/actions/auth"
  
  export default {
    [AUTH_REQUEST]: state => {
      state.authStatus = "loading"
    },
    [AUTH_SUCCESS]: (state,Token) => {
      state.authStatus = "success"
      localStorage.setItem("te-token",Token);
    },
    [AUTH_ERROR]: (state,Error) => {
      state.authStatus = "error";
      state.authError = Error;
    },
    [AUTH_LOGOUT]: state => {
      state.authStatus = ""
      state.token = false;
      state.$Request = "";
      state.authError = "";
      state.loginInfor = {
        _perm:null
      }
    },
    LoginInfor: (state,data) =>{
      state.loginInfor = data
    },
    packageFilter: (state,data) =>{
      state.packageFilter = data
    }
}