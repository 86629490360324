/* eslint-disable */
import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT,
  } from "@/store/actions/auth"
  // import { settings } from "@/settings"
  import router from "../router";
  import axios from 'axios'
  import { API } from '@/plugins/helper/api.js'
  export default {
    PackageFilter: ({ commit },data) =>{
      commit('packageFilter',data);
    },
    LoginInfor: ({ commit },data) =>{
      commit('LoginInfor',data);
    },
    [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
      return new Promise((resolve, reject) => {
        commit(AUTH_REQUEST);
        axios.post(API+'/staff/login', user).then(res => {
          if (res.status == 200 && res.data.token) {
            const Token = res.data.token;
            commit(AUTH_SUCCESS,Token);
          }else{
            commit(AUTH_ERROR, res.data.error);
          }
          resolve(res);
        }).catch(err => {
          commit(AUTH_ERROR, err);
          localStorage.removeItem("te-token");
          reject(err);
        });
      });
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      return new Promise(resolve => {
        localStorage.removeItem('te-token')
        commit(AUTH_LOGOUT)
        router.push('login')
        resolve()
      })
    },
  }