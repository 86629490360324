export default {
    token: localStorage.getItem('te-token') || false,
    authStatus: '',
    authError: '',
    loginInfor: {
        _perm:null,
        pending_order: 0,
        Telegram: null,
        CKA: 0,
        CKB: 0,
    },
    packageFilter:{
        Customer:null,
        Sale:null,
        Status:null,
    }
}